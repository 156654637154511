import config, { IConfigService } from '../config/config.service'
import http from '@app/core/http/http-client'
import {
  CheckResponse,
  LoginPost,
  RequestRecover,
  RequestCredentials,
  User,
} from './types'
import { IHttpClient } from '@app/core/http'
import { errorFactory } from '@app/core/api-errors/error-factory'

const getBaseUrl = (config: IConfigService) => `${config.baseUrl}/auth`

const login =
  (config: IConfigService, http: IHttpClient) =>
  async (params: LoginPost): Promise<User | undefined> => {
    try {
      return await http.post(`${getBaseUrl(config)}/login`, params)
    } catch (e) {
      throw errorFactory(e)
    }
  }

const logout =
  (config: IConfigService, http: IHttpClient) =>
  async (): Promise<void | undefined> => {
    try {
      return await http.post<object, void>(`${getBaseUrl(config)}/logout`)
    } catch (e) {
      throw errorFactory(e)
    }
  }

const checkSession =
  (config: IConfigService, http: IHttpClient) =>
  async (): Promise<CheckResponse | undefined> => {
    try {
      return await http.get(`${getBaseUrl(config)}/check`)
    } catch (e) {
      throw errorFactory(e)
    }
  }

const recover =
  (config: IConfigService, http: IHttpClient) =>
  async (params: RequestRecover): Promise<void> => {
    try {
      return await http.post<object, void>(
        `${getBaseUrl(config)}/recover`,
        params,
      )
    } catch (e) {
      throw errorFactory(e)
    }
  }

const recoverByCredentials =
  (config: IConfigService, http: IHttpClient) =>
  async (params: RequestCredentials): Promise<void> => {
    try {
      return await http.post<object, void>(
        `${getBaseUrl(config)}/recover-credentials`,
        params,
      )
    } catch (e) {
      throw errorFactory(e)
    }
  }

const factory = (config: IConfigService, http: IHttpClient) => {
  return {
    login: login(config, http),
    logout: logout(config, http),
    checkSession: checkSession(config, http),
    recover: recover(config, http),
    recoverByCredentials: recoverByCredentials(config, http),
  }
}

export default factory(config, http)
