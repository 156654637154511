import { useTranslation } from 'react-i18next'
import { CiCircleCheck } from 'react-icons/ci'
import { Link } from 'react-router-dom'

import './RecoverSentPage.scss'

export const RecoverSentPage = () => {
  const { t } = useTranslation()
  return (
    <div className="RecoverSentPage d-flex gap-3">
      <CiCircleCheck />
      <h1 className="h5">{t('recover-sent.title')}</h1>
      <p className="RecoverSentPage-desc">{t('recover-sent.description')}</p>
      <Link className="btn btn-link link-primary fs-sm" to="/login">
        {t('recover.login')}
      </Link>
    </div>
  )
}
