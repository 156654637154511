import { useCallback } from 'react'
import { ToastOptions, toast } from 'react-toastify'

const defaultConfiguration: ToastOptions<unknown> = {
  position: 'top-center',
}

const uploadMessageConfiguration: ToastOptions<unknown> = {
  position: 'bottom-right',
  autoClose: false,
}

export const useToast = () => {
  const toastError = useCallback((message: string) => {
    toast.error(message, Object.assign({ toastId: 'error-toast' }, defaultConfiguration))
  }, [])

  const toastSuccess = useCallback((message: string) => {
    toast.success(message, defaultConfiguration)
  }, [])

  const toastInfo = useCallback((message: string) => {
    toast.info(message, uploadMessageConfiguration)
  }, [])

  return {
    toastError,
    toastSuccess,
    toastInfo,
  }
}
