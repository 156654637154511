import { Dropdown, DropdownButton } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { CiLogout, CiSettings, CiUser } from 'react-icons/ci'
import { useNavigate } from 'react-router-dom'
import { ProfileMenu } from '../../profile-menu/ProfileMenu'

import './ProfileSetting.scss'
import { useAuth } from '@app/hooks'

interface ProfileSettingProps {
  isMobile?: boolean
}

export const ProfileSetting = ({ isMobile }: ProfileSettingProps) => {
  const navigate = useNavigate()
  const { logout } = useAuth()
  const { t } = useTranslation()

  return (
    <div className="ProfileSetting">
      {!isMobile ? (
        <DropdownButton
          id="dropdown-basic-button"
          title={<CiSettings />}
          variant="icon"
        >
          <Dropdown.Item onClick={() => navigate('/profile')}>
            <CiUser />
            {t('menu.profile')}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={logout}>
            <CiLogout />
            {t('menu.logout')}
          </Dropdown.Item>
        </DropdownButton>
      ) : (
        <ProfileMenu placement="end" name="end" />
      )}
    </div>
  )
}
