import { downloadDocument } from '@app/utils'
import { FC, useMemo } from 'react'
import Dropdown from 'react-bootstrap/esm/Dropdown'
import { useTranslation } from 'react-i18next'
import { HiMiniPlusSmall } from 'react-icons/hi2'

import './MediaMenuActions.scss'

type MediaMenuActionsProps = {
  id: string
  url?: string
  handleClear?: () => void
  handleUpload?: () => void
}

export const MediaMenuActions: FC<MediaMenuActionsProps> = ({
  url,
  id,
  // handleClear,
  handleUpload,
}) => {
  const { t } = useTranslation()

  const showMenu = useMemo(
    () => url || (!url && handleUpload),
    [handleUpload, url],
  )

  return (
    <Dropdown align="end" className="MediaMenuActions">
      <Dropdown.Toggle
        variant="link"
        id={`media-menu-actions-${id}`}
        disabled={!showMenu}
      >
        <HiMiniPlusSmall size={24} className="MediaMenuActions-icon" />
      </Dropdown.Toggle>

      {showMenu && (
        <Dropdown.Menu>
          {url ? (
            <>
              {handleUpload && (
                <Dropdown.Item
                  as="button"
                  type="button"
                  onClick={() => handleUpload()}
                >
                  {t('cases.multimedia.uploads')}
                </Dropdown.Item>
              )}
              <Dropdown.Item
                as="button"
                type="button"
                onClick={() => downloadDocument(url)}
              >
                {t('cases.multimedia.download')}
              </Dropdown.Item>
              {/* {handleClear && ( */}
              {/*  <Dropdown.Item */}
              {/*    as="button" */}
              {/*    type="button" */}
              {/*    onClick={() => handleClear()} */}
              {/*  > */}
              {/*    {t('cases.multimedia.clear')} */}
              {/*  </Dropdown.Item> */}
              {/* )} */}
            </>
          ) : (
            <>
              {handleUpload && (
                <Dropdown.Item
                  as="button"
                  type="button"
                  onClick={() => handleUpload()}
                >
                  {t('cases.multimedia.upload')}
                </Dropdown.Item>
              )}
            </>
          )}
        </Dropdown.Menu>
      )}
    </Dropdown>
  )
}
