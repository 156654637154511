import '../i18n/config'
import { AppRouter } from './routes/AppRouter'
import { SpinnerProvider } from './context/SpinnerProvider'
import { ConfirmationProvider } from '@app/components'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

export const App = () => {
  const { i18n } = useTranslation()

  return (
    <>
      <Helmet>
        <html lang={i18n.language} />
      </Helmet>
      <SpinnerProvider>
        <ConfirmationProvider>
          <AppRouter />
        </ConfirmationProvider>
      </SpinnerProvider>
    </>
  )
}
