import moment from 'moment'
import 'moment-timezone';

export function useDateTime(timezone: string|undefined|null = undefined) {

  function formatDate(value: string | Date | undefined): string {
    if (!value) {
      return ''
    }

    return moment(value).tz(timezone ?? 'UTC').format('DD/MM/YYYY')
  }

  function formatTime(value: string | Date): string {
    if (!value) {
      return ''
    }

    return moment(value).tz(timezone ?? 'UTC').format('HH:mm')
  }

  return {
    formatDate,
    formatTime
  }
}
