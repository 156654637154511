import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { DashboardPage } from '../pages'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'
import { ClinicRoutes } from '@app/pages/clinics/routes'
import { ProfileRoutes } from '@app/pages/profile/routes'
import { BillingAddressRoutes } from '@app/pages/billing-address/routes'
import { CasesRoutes } from '@app/pages/cases/routes'
import { NotFound } from '@app/pages/errors'
import { ConfirmationProvider } from '@app/components'
import { InvoicesRoutes } from '@app/pages/invoices/routes'
import { useProfile } from '@app/hooks'
import { LoginPage } from '@pages/login/LoginPage.tsx'
import Spinner from '@app/components/spinner/Spinner'
import { useSpinner } from '@app/context/SpinnerProvider.tsx'
import { ResponseInterceptor } from '@app/context/ResponseInterceptor'
import { AuthLayout, MainLayout } from '@app/layouts'
import { RecoverPasswordPage } from '@app/pages/recover-password/RecoverPasswordPage'
import { RecoverSentPage } from '@app/pages/recover-sent/RecoverSentPage'
import { NewPasswordPage } from '@app/pages/new-password/NewPasswordPage'
import PublicRoutes from './PublicRoutes'

export const AppRouter: React.FC = () => {
  const { i18n } = useTranslation()
  const { userLanguage } = useProfile()
  const { showSpinner } = useSpinner()

  useEffect(() => {
    i18n.changeLanguage(userLanguage)
  }, [userLanguage, i18n])

  return (
    <>
      {showSpinner && <Spinner />}
      <BrowserRouter>
        <ResponseInterceptor />
        <ConfirmationProvider>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route element={<DashboardPage />} path="/dashboard" />
              <Route element={<CasesRoutes />} path="/patients/*" />
              <Route element={<ClinicRoutes />} path="/clinics/*" />
              <Route
                element={<BillingAddressRoutes />}
                path="/billing-address/*"
              />
              <Route element={<InvoicesRoutes />} path="/invoices/*" />
              <Route element={<ProfileRoutes />} path="/profile/*" />
              <Route path="*" element={<NotFound />} />
            </Route>

            <Route element={<PublicRoutes />}>
              <Route element={<AuthRoutesWithLayout />}>
                <Route element={<LoginPage />} path="/login" />
                <Route element={<RecoverPasswordPage />} path="/recover-password" />
                <Route
                  element={<RecoverSentPage />}
                  path="/recover-password-email-sent"
                />
                <Route
                  element={<NewPasswordPage />}
                  path="/confirmation-email/:emailToken"
                />
              </Route>
            </Route>

            <Route index element={<Navigate to="dashboard" replace />} />
            <Route
              path="*"
              element={
                <MainLayout>
                  <NotFound />
                </MainLayout>
              }
            />
          </Routes>
          <ToastContainer />
        </ConfirmationProvider>
      </BrowserRouter>
    </>
  )
}

const AuthRoutesWithLayout = () => {
  return (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  )
}
