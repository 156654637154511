import { Place } from '../places/types'

export interface LoginPost {
  email: string
  password: string
}
export interface RequestRecover {
  email: string
}

export interface RequestCredentials {
  code: string
  password: string
}

export interface User {
  data: LoginResponse
}

export interface LoginResponse {
  token: string
  user: DetailUser
}

export interface CheckResponse {
  data: DetailUser
}

export enum Gender {
  M = 'male',
  F = 'female',
  O = 'other',
}

export enum RangeAge {
  A = 'less_than_40',
  B = 'between_40_60',
  C = 'greater_than_60',
}

export enum Speciality {
  OD = '1',
  OR = '2',
  OT = '3',
}

export interface DetailUser {
  id: number
  username: string
  email: string
  lastLogin: string | null
  locale: string
  roles: string[]
  firstName: string
  lastNames: string
  gender: Gender
  collegiateNumber: string
  speciality: Speciality | null
  proclinicCode: string | null
  phone: string | null
  clinicAddress: string
  province: Place & {
    country: Place
  }
  credit: number
  clinicVat: string | null
  clinicPostalCode: string | null
  ageRange: RangeAge | null
  knowAboutUsFrom: number | null
  createdAt: string
  avatar: string | null
  timezone: string | null
}
