import { useAppDispatch, useAppSelector } from '@app/store/hooks'
import {
  showAdditionalAlignersAction,
  showRefinementAction,
} from '@app/store/slices'
import Modal from 'react-bootstrap/esm/Modal'
import { useTranslation } from 'react-i18next'
import { ButtonAsync } from '@app/components'
import { useCallback } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Form, InputControl, TextareaControl } from '@app/components/forms'
import { yupResolver } from '@hookform/resolvers/yup'
import { refinementFormValidationSchema } from './validation'
import { RefinementFormFields } from './types'
import { useParams, useNavigate } from 'react-router-dom'
import { reloadCaseAction } from '@app/store/slices/case/caseSlice'
import { useRefinements } from '@app/hooks/refinements/useRefinements'
import { HiInformationCircle } from 'react-icons/hi'

import './RefinementModal.scss'

const defaultValues: RefinementFormFields = {
  reason: '',
  details: '',
}
export const RefinementModal = () => {
  const { t } = useTranslation()
  const { caseId } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { showRefinement } = useAppSelector(state => state.ui)

  const { handleSend } = useRefinements(caseId!)
  const methods = useForm<RefinementFormFields>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(refinementFormValidationSchema(t)),
  })
  const {
    formState: { isSubmitting, isValid },
  } = methods

  const handleSubmitForm: SubmitHandler<RefinementFormFields> = useCallback(
    async (formValues: RefinementFormFields) => {
      const { reason, details } = formValues
      const formData = new FormData()
      formData.append('reason', reason)
      formData.append('details', details)

      try {
        await handleSend(formData)
        dispatch(showAdditionalAlignersAction(false))
        dispatch(reloadCaseAction(true))
        navigate(`/patients/${caseId}?tab=media`)
      } catch (error) {}
    },
    [dispatch, handleSend, navigate, caseId],
  )

  return (
    <Modal
      show={showRefinement}
      onHide={() => dispatch(showRefinementAction(false))}
      className="modal-md"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('cases.actions.refine')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="refinement-modal">
        <FormProvider {...methods}>
          <Form onValid={handleSubmitForm}>
            <div className="d-flex flex-column gap-2">
              <InputControl
                type="text"
                name="reason"
                label={t('cases.actions.reason')}
                placeholder={t('cases.actions.reason')}
                required
              />
              <TextareaControl<RefinementFormFields>
                rows={3}
                name="details"
                label={t('cases.actions.details')}
                required
              />
              <div className="mt-1 description">
                <HiInformationCircle /> {t('cases.actions.refine-description')}
              </div>
              <ButtonAsync
                className="btn-primary mt-3"
                disabled={!isValid}
                isSubmitting={isSubmitting}
              >
                {t('cases.actions.request')}
              </ButtonAsync>
            </div>
          </Form>
        </FormProvider>
      </Modal.Body>
    </Modal>
  )
}
