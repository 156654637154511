import { PropsWithChildren } from 'react'
import Image from 'react-bootstrap/Image'
import logo from '@images/logo-secretaligner-dark.svg'
import { useTranslation } from 'react-i18next'

import './AuthLayout.scss'

export const AuthLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation()
  return (
    <div className="container-fluid AuthLayout">
      <div className="row">
        <div className="col-12 col-lg-5 AuthLayout-left">
          <div className="AuthLayout-image">
            <Image
              src={logo}
              alt={t('auth-layout.secretaligner')}
              title={t('auth-layout.secretaligner')}
            />
          </div>
          <div className="AuthLayout-form">{children}</div>
        </div>
        <div className="col-lg-7 AuthLayout-background d-none d-lg-flex">
          <div className="contain-text">
            <p>{t('auth-layout.title')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
