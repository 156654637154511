import { ButtonAsync } from '@app/components'
import { Form, InputControl } from '@app/components/forms'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { confirmPassFormValidationSchema } from './validation'
import { useParams } from 'react-router-dom'
import { useAuth } from '@app/hooks'

type ConfirmPassFormFields = {
  password: string
  passwordConfirmation: string
}

const defaultValues: ConfirmPassFormFields = {
  password: '',
  passwordConfirmation: '',
}

export const ConfirmPassForm = () => {
  const { t } = useTranslation()
  const { emailToken } = useParams()
  const methods = useForm<ConfirmPassFormFields>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(confirmPassFormValidationSchema(t)),
  })
  const { recoverByCredentials } = useAuth()
  const {
    formState: { isSubmitting, isValid },
  } = methods

  const handleSubmitForm: SubmitHandler<ConfirmPassFormFields> = useCallback(
    async (formValues: ConfirmPassFormFields) => {
      const { password } = formValues
      const credentials = {
        code: emailToken!,
        password,
      }
      await recoverByCredentials(credentials)
    },
    [emailToken, recoverByCredentials],
  )
  return (
    <div className="ConfirmPassForm d-flex gap-3 flex-column">
      <h1 className="h3">{t('confirm-pass.title')}</h1>
      <FormProvider {...methods}>
        <Form onValid={handleSubmitForm}>
          <div className="d-flex flex-column gap-2">
            <InputControl
              type="password"
              name="password"
              label={t('confirm-pass.title')}
              placeholder={t('confirm-pass.title')}
              required
            />
            <InputControl
              type="password"
              name="passwordConfirmation"
              label={t('profile.password.confirm')}
              placeholder={t('profile.password.confirm')}
              required
            />

            <ButtonAsync
              className="btn-primary mt-3"
              disabled={!isValid}
              isSubmitting={isSubmitting}
            >
              {t('common.save')}
            </ButtonAsync>
          </div>
        </Form>
      </FormProvider>
    </div>
  )
}
