import { FC } from 'react'
import './Loading.scss'

type LoadingProps = {
  className?: string
  size?: 'md' | 'sm'
}

export const Loading: FC<LoadingProps> = ({ className = '', size = 'md' }) => {
  return (
    <div className={`loading-container ${className}`}>
      <div className={`spinner spinner-${size}`} />
    </div>
  )
}
