import { AxiosError } from 'axios'

interface ApiErrorContext {
  value: string
  name: string
}

export type ApiError = { property?: string; errorContext?: ApiErrorContext[] }

export type ApiBadRequestResponse = ApiError[]
export type ApiConflictErrorResponse = ApiError[]

export interface IApiError {
  errors: ApiError[]
  readonly message: string
}

export abstract class AbstractApiError extends Error implements IApiError {
  constructor(
    public readonly errors: ApiError[],
    public readonly message: string,
  ) {
    super(message)
  }
}

export class ApiBadRequestError extends AbstractApiError {
  constructor(errors: ApiBadRequestResponse, message?: string) {
    super(errors, message ?? 'API responded with BadRequest')
    this.name = 'ApiBadRequestError'
  }
}

export class ApiNotFoundError extends AbstractApiError {
  constructor(errors: ApiError[] = [], message?: string) {
    super(errors, message ?? 'API responded with NotFound')
    this.name = 'ApiNotFoundError'
  }
}

export class ApiConflictError extends AbstractApiError {
  constructor(errors: ApiError[] = [], message?: string) {
    super(errors, message ?? 'API responded with Conflict')
    this.name = 'ApiConflictError'
  }
}

export class ApiForbiddenError extends AbstractApiError {
  constructor(errors: ApiError[] = [], message?: string) {
    super(errors, message ?? 'API responded with Forbidden')
    this.name = 'ApiForbiddenError'
  }
}

export class ApiUnprocessableEntityError extends AbstractApiError {

  constructor(errors: ApiError[] = [], message?: string) {
    super(errors, message ?? 'API responded with Unprocessable Entity')
    this.name = 'ApiUnprocessableEntityError'
  }
}

export function isAxiosError<T>(
  error: AxiosError | unknown,
): error is AxiosError<T> {
  return error ? (error as AxiosError).isAxiosError : false
}
