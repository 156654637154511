import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form, InputControl } from '@app/components/forms'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@app/hooks'
import { recoverFormValidationSchema } from './validation'
import { Link } from 'react-router-dom'
import { ButtonAsync } from '@app/components'

type RecoverFormFields = {
  email: string
}

const defaultValues: RecoverFormFields = {
  email: '',
}

export const RecoverForm = () => {
  const { t } = useTranslation()
  const methods = useForm<RecoverFormFields>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(recoverFormValidationSchema()),
  })
  const { recover } = useAuth()

  const {
    formState: { isSubmitting, isValid },
  } = methods

  const handleSubmitForm: SubmitHandler<RecoverFormFields> = useCallback(
    async (formValues: RecoverFormFields) => {
      const { email } = formValues
      await recover({ email })
    },
    [recover],
  )
  return (
    <div className="RecoverForm d-flex gap-3 flex-column">
      <h1 className="h3">{t('recover.title')}</h1>

      <FormProvider {...methods}>
        <Form onValid={handleSubmitForm}>
          <div className="d-flex flex-column gap-2">
            <InputControl
              type="email"
              name="email"
              label={t('recover.user.label')}
              placeholder={t('recover.user.placeholder')}
              required
            />

            <ButtonAsync
              className="btn-primary mt-3"
              disabled={!isValid}
              isSubmitting={isSubmitting}
            >
              {t('recover.title')}
            </ButtonAsync>
          </div>
        </Form>
      </FormProvider>

      <div className="fs-sm text-center">
        <div>{t('recover.remember')}</div>
        <Link className="btn btn-link link-primary fs-sm" to="/login">
          {t('recover.login')}
        </Link>
      </div>
    </div>
  )
}
