import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form, InputControl } from '@app/components/forms'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { loginFormValidationSchema } from './validation'
import { useAuth, useToast } from '@app/hooks'
import { Link } from 'react-router-dom'
import { ButtonAsync } from '@app/components'
import { ApiUnprocessableEntityError } from '@core/api-errors'

type LoginFormFields = {
  email: string
  password: string
}

const defaultValues: LoginFormFields = {
  email: '',
  password: '',
}

export const LoginForm = () => {
  const { t } = useTranslation()
  const { toastError } = useToast()
  const methods = useForm<LoginFormFields>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(loginFormValidationSchema()),
  })
  const navigate = useNavigate()
  const { login } = useAuth()

  const {
    reset,
    formState: { isSubmitting, isValid },
  } = methods

  const handleSubmitForm: SubmitHandler<LoginFormFields> = useCallback(
    async (formValues: LoginFormFields) => {
      const { email, password } = formValues
      try {
        const params = {
          email,
          password,
        }
        await login(params)
        navigate('/dashboard')
        reset()
      }
      catch (error) {
        if (error instanceof ApiUnprocessableEntityError) {
          methods.setError('email', {
            type: 'custom',
            message: t('login.errors.invalid'),
          })

          return
        }

        toastError(t('login.errors.login'))
      }
    },
    [methods, login, navigate, reset, t, toastError],
  )

  return (
    <div className="LoginForm d-flex gap-3 flex-column">
      <h1 className="h3">{t('login.title')}</h1>

      <FormProvider {...methods}>
        <Form onValid={handleSubmitForm}>
          <div className="d-flex flex-column gap-2">
            <InputControl
              type="email"
              name="email"
              label={t('login.user.label')}
              placeholder={t('login.user.placeholder')}
              required
            />
            <InputControl
              type="password"
              name="password"
              label={t('login.password.label')}
              placeholder={t('login.password.placeholder')}
              required
            />
            <Link
              className="btn btn-link link-primary fs-sm text-end p-0"
              to="/recover-password"
            >
              {t('login.forgot-pass')}
            </Link>

            <ButtonAsync
              className="btn-primary mt-3"
              disabled={!isValid}
              isSubmitting={isSubmitting}
            >
              {t('login.access')}
            </ButtonAsync>
          </div>
        </Form>
      </FormProvider>

      <div className="fs-sm text-center">
        <div>{t('login.sign-up')}</div>
        <a
          className="link-primary"
          href="https://secretaligner.com/contacto/"
          target="_blank"
        >
          {t('login.contact')}
        </a>
      </div>
    </div>
  )
}
