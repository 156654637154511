import { FC, useMemo } from 'react'
import Dropdown from 'react-bootstrap/esm/Dropdown'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { MdOutlineFileDownload } from 'react-icons/md'
// import { BsTrash } from 'react-icons/bs'
import { CaseFile } from '@app/services/files/types'
import { downloadDocument } from '@app/utils'
import './MediaMenuAdditionalFiles.scss'
import { useDateTime } from '@hooks/dates/useDateTime.ts'
import { useAppSelector } from '@app/store/hooks.ts'

type MediaMenuAdditionalFilesProps = {
  id: string
  files: CaseFile[]
  handleDelete: (file: CaseFile) => Promise<void>
}

export const MediaMenuAdditionalFiles: FC<MediaMenuAdditionalFilesProps> = ({
  id,
  files,
  // handleDelete,
}) => {

  const { user } = useAppSelector(state => state.user)
  const { formatDate } = useDateTime(user?.timezone)
  const showDropdown = useMemo(() => files.length > 0, [files.length])

  // const onDelete = async (file: CaseFile) => {
  //   await handleDelete(file)
  // }

  return (
    <Dropdown align="end" className="MediaMenuAdditionalFiles">
      <Dropdown.Toggle
        variant="link"
        id={`media-menu-additional-files-${id}`}
        disabled={!showDropdown}
      >
        <RiArrowDropDownLine
          size={24}
          className="MediaMenuAdditionalFiles-icon"
        />
      </Dropdown.Toggle>

      {showDropdown && (
        <Dropdown.Menu>
          {files.map(file => (
            <Dropdown.Item
              as="div"
              key={file.id}
              className="dropdown-item-custom"
            >
              <span className="file-info">
                {file.fileOriginalName} - {formatDate(file.createdAt)}
              </span>
              <div className="icon-actions">
                <MdOutlineFileDownload
                  size={20}
                  onClick={() => downloadDocument(file.url)}
                />
                {/* <BsTrash size={18} onClick={() => onDelete(file)} /> */}
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </Dropdown>
  )
}
