import { BreadcrumbDynamic, Card, Main, PageHeader, StateHandler, StatusBadge, Tabs } from '@app/components'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useCaseDetail } from '@app/hooks'
import { useParams } from 'react-router-dom'
import { Case, CaseStatus, CaseType } from '@app/services/cases/types'
import { ItemBreadCrumb } from '@app/components/breadcrumb-dynamic/types'
import { CaseDetailSection } from '../types'
import { CaseDetailInformation } from './information/CaseDetailInformation'
import { CaseDetailAssessments } from './assessments/CaseDetailAssessments'
import { CaseDetailMultimedia } from './multimedia/CaseDetailMultimedia'
import { ActionsWidget } from './actions-widget/ActionsWidget'
import { useNavTabs } from '@app/hooks/'
import { CaseDetailBilling } from './billing/CaseDetailBilling'
import { RequestedWidget } from './requested-widget/RequestedWidget'
import { useAppDispatch, useAppSelector } from '@app/store/hooks'
import { reloadCaseAction } from '@app/store/slices/case/caseSlice'
import { CaseDetailDocu } from './documentation/CaseDetailDocu'

import './CaseDetailPage.scss'

export const CaseDetailPage = () => {
  const { caseId } = useParams()
  const { reloadCase } = useAppSelector(state => state.case)
  const dispatch = useAppDispatch()
  const {
    state: { loaded, error, case: caseDetail },
    loadCase,
  } = useCaseDetail(caseId!)

  useEffect(() => {
    if (reloadCase) {
      loadCase(caseId!)
      dispatch(reloadCaseAction(false))
    }
  }, [caseId, dispatch, loadCase, reloadCase])

  return (
    <Main ariaLabelledby="case-detail-page" className="CaseDetailPage">
      <StateHandler loaded={loaded} error={error}>
        <CaseContent caseDetail={caseDetail!} />
      </StateHandler>
    </Main>
  )
}

const CaseContent: FC<{ caseDetail: Case }> = ({ caseDetail }) => {
  const { t } = useTranslation()
  const { activeTab, handleOnSelect } = useNavTabs(
    CaseDetailSection.Information,
  )

  const breadcrumbItems: ItemBreadCrumb[] = [
    { label: t('menu.cases'), href: '/patients' },
    { label: caseDetail.generalInfo.patient.name },
  ]

  return (
    <>
      <PageHeader id="cases-detail-page">
        <BreadcrumbDynamic items={breadcrumbItems} />

        <div className="CasesDetailPage-idContent">
          <span className="idName">{t('cases.id', { id: caseDetail.id })}</span>
          <StatusBadge status={caseDetail.status} outline />
        </div>
      </PageHeader>

      {caseDetail.status === CaseStatus.Requested && (
        <RequestedWidget className="mb-3" />
      )}

      <Card>
        <Card.Body>
          <Tabs
            activeKey={activeTab}
            className="mb-4"
            onSelect={k => handleOnSelect(k as CaseDetailSection)}
          >
            <Tabs.Item
              eventKey={CaseDetailSection.Information}
              title={t(`cases.${CaseDetailSection.Information}`)}
            >
              <CaseDetailInformation caseDetail={caseDetail} />
            </Tabs.Item>
            {caseDetail.type !== CaseType.SecretRetainer ? (<Tabs.Item
              eventKey={CaseDetailSection.Assessments}
              title={t(`cases.${CaseDetailSection.Assessments}`)}
            >
              <CaseDetailAssessments
                caseId={caseDetail.id}
                nextPhaseAvailable={caseDetail.nextPhaseAvailable}
                status={caseDetail.status}
              />
            </Tabs.Item>) : null}
            <Tabs.Item
              eventKey={CaseDetailSection.Media}
              title={t(`cases.${CaseDetailSection.Media}`)}
            >
              <CaseDetailMultimedia idCase={caseDetail.id} />
            </Tabs.Item>
            <Tabs.Item
              eventKey={CaseDetailSection.Billing}
              title={t(`cases.${CaseDetailSection.Billing}`)}
            >
              <CaseDetailBilling idCase={caseDetail.id} />
            </Tabs.Item>
            <Tabs.Item
              eventKey={CaseDetailSection.Documents}
              title={t(`cases.${CaseDetailSection.Documents}`)}
            >
              <CaseDetailDocu idCase={caseDetail.id} />
            </Tabs.Item>
          </Tabs>
        </Card.Body>
      </Card>

      <ActionsWidget status={caseDetail.status} idCase={caseDetail.id} />
    </>
  )
}
