import { useCallback } from 'react'
import { useCaseFileDetail } from './useCaseFileDetail'
import { CaseFile } from '@app/services/files/types.ts'

export const useHandleDelete = (caseId: string) => {
  const { deleteFile } = useCaseFileDetail(caseId)

  const handleDelete = useCallback(
    async (files?: CaseFile[]) => {
      if (files && files.length > 0) {
        for (const file of files) {
          await deleteFile(file.id.toString())
        }
      }
    },
    [deleteFile],
  )

  return { handleDelete }
}
