import { useAuth, useToast } from '@app/hooks'
import { AxiosError } from 'axios'
import { useEffect } from 'react'

import http from '@app/core/http/http-client'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const ResponseInterceptor = () => {
  const { t } = useTranslation()
  const { toastError } = useToast()
  const { destroySession, isAuthenticated } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    http.provider.interceptors.response.use(undefined, (error: AxiosError) => {
      if (isAuthenticated() && error.response?.status === 401) {
        destroySession()
        toastError(t('errors.session-lost'))
        return
      }

      return Promise.reject(error)
    })
  }, [isAuthenticated, destroySession, navigate, toastError, t])

  return null
}
