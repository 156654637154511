import { Main, PageHeader, StateHandler, Table } from '@app/components'
import { INVOICES_PAGE_SIZE, useInvoices } from '@app/hooks'
import { useTranslation } from 'react-i18next'
import { columnConfig, getTableColumns, getTableRows } from './utils'
import { Pagination } from '@app/components/pagination/Pagination'
import { InvoicePaginationQuery } from '@app/services/invoices/types'
import {
  FiltersProvider,
  useFiltersContext,
} from '@app/components/filters/context'
import { SearchControl } from '@app/components/search-control/search-control/SearchControl'
import { FilterButton, FilterPanel } from '@app/components/filters'
import { useState } from 'react'
import { InvoicesFiltersForm } from './shared/filters-form/InvoicesFiltersForm'
import { BarChart } from './bar-chart/BarChart'
import { useDateTime } from '@hooks/dates/useDateTime.ts'
import { useAppSelector } from '@app/store/hooks.ts'

export const InvoicesPage = () => {
  const { t } = useTranslation()
  return (
    <FiltersProvider>
      <Main ariaLabelledby="invoices-page" className="InvoicesPage">
        <PageHeader id="invoices-page">{t('menu.invoices')}</PageHeader>
        <InvoicesContent />
      </Main>
    </FiltersProvider>
  )
}

const InvoicesContent = () => {
  const filterPanelId = 'invoices-filter-panel'
  const { t } = useTranslation()
  const { user } = useAppSelector(state => state.user)
  const { formatDate } = useDateTime(user?.timezone)
  const [openFilters, setOpenFilters] = useState<boolean>(false)
  const { filter } = useFiltersContext()
  const {
    state: { loaded, error, invoices, page, lastPage, total, aggregates },
    setPage,
    debouncedSearch,
    setSortBy,
  } = useInvoices({ filter } as InvoicePaginationQuery)

  const filterButton = (
    <FilterButton
      id={filterPanelId}
      onClick={setOpenFilters}
      open={openFilters}
    />
  )
  return (
    <>
      <SearchControl
        onSearch={search => debouncedSearch(search)}
        filterButton={filterButton}
        withoutLink={true}
      />
      <FilterPanel open={openFilters} id={filterPanelId}>
        <InvoicesFiltersForm />
      </FilterPanel>
      <div>
        <Table
          columns={getTableColumns(t)}
          rows={getTableRows(invoices ?? [], t, formatDate)}
          config={{
            columns: columnConfig,
            numRows: INVOICES_PAGE_SIZE,
          }}
          state={{ loaded, error }}
          onSortBy={setSortBy}
        />
      </div>
      <Pagination
        loading={!loaded}
        currentPage={page}
        totalPages={lastPage}
        totalElements={total || 0}
        onChange={pageIndex => setPage(pageIndex)}
      />
      {aggregates && aggregates?.totalMonthlyTrend.length > 0 && (
        <StateHandler loaded={loaded} error={error}>
          <BarChart aggregates={aggregates!} />
        </StateHandler>
      )}
    </>
  )
}
