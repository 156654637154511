export function groupBy<T, K extends string>(
  array: T[],
  key: keyof T,
): Record<K, T[]> {
  return array.reduce(
    (result, currentValue) => {
      const keyValue = currentValue[key] as unknown as K
      if (!result[keyValue]) {
        result[keyValue] = []
      }
      result[keyValue].push(currentValue)

      return result
    },
    {} as Record<K, T[]>,
  )
}
