import { Table } from '@app/components'
import {
  TableColumnType,
  TableConfigColType,
  TableRowType,
} from '@app/components/table/types'
import { Tooltip } from '@app/components/tooltip/Tooltip'
import { CaseBase, CasePhaseType, CaseStatus } from '@app/services/cases/types'
import { getFrontalFilePhoto } from '@app/utils/cases'
import { TFunction } from 'i18next'
import { getEnumKeyByValue } from '@app/utils'
import { VariationNames } from '@app/services/files/types.ts'
import { useDateTime } from '@hooks/dates/useDateTime.ts'

export const columnConfig: TableConfigColType[] = [
  {
    target: 'clinic',
    hideMobile: true,
  },
  {
    target: 'phase',
    orderable: false,
    hideMobile: true,
  },
  {
    target: 'updatedAt',
    hideMobile: true,
  },
]

export const getTableColumns = (t: TFunction): TableColumnType[] => [
  {
    id: 'name',
    title: t('cases.name'),
  },
  {
    id: 'clinic',
    title: t('cases.clinic'),
  },
  {
    id: 'status',
    title: t('cases.status.title'),
  },
  {
    id: 'phase',
    title: t('cases.phase'),
  },
  {
    id: 'updatedAt',
    title: t('cases.updatedat'),
  },
]

const mapCaseToRow = async (
  data: CaseBase,
  t: TFunction,
): Promise<TableRowType> => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { formatDate } = useDateTime()
  const caseName = data.generalInfo?.patient?.name.trim()
    ? data.generalInfo?.patient?.name
    : t('cases.name-default')

  const photoUrl = getFrontalFilePhoto(data.files, VariationNames.Miniature)

  return {
    name: (
      <>
        <Table.Avatar src={photoUrl} />{' '}
        <Table.Link to={`/patients/${data.id}`}>{caseName}</Table.Link>
      </>
    ),
    clinic: data.generalInfo?.clinic?.name,
    status: (
      <Tooltip
        id={data.id}
        title={t(
          `cases.status.${getEnumKeyByValue(CaseStatus, data.status)?.toLowerCase()}`,
        )}
      >
        <Table.Pill status={data.status} />
      </Tooltip>
    ),
    phase: t(
      `cases.phase-types.${getEnumKeyByValue(CasePhaseType, data.phase)?.toLowerCase()}`,
    ),
    updatedAt: formatDate(data.updatedAt),
  }
}

export const getTableRows = async (
  cases: CaseBase[],
  t: TFunction,
): Promise<TableRowType[]> => {
  return Promise.all(cases.map(data => mapCaseToRow(data, t)))
}
