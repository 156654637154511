import { object, string, AnyObjectSchema } from 'yup'

export function loginFormValidationSchema(): AnyObjectSchema {
  return object()
    .shape({
      email: string().email().required(),
      password: string().min(4).max(32).required(),
    })
    .required()
}
