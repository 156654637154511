import { TFunction } from 'i18next'
import { object, string, AnyObjectSchema } from 'yup'

export function confirmPassFormValidationSchema(t: TFunction): AnyObjectSchema {
  return object()
    .shape({
      password: string()
        .min(
          4,
          t('validation.min', {
            number: 4,
          }),
        )
        .max(
          32,
          t('validation.max', {
            number: 32,
          }),
        )
        .required(t('validation.required')),
      passwordConfirmation: string()
        .min(
          4,
          t('validation.min', {
            number: 4,
          }),
        )
        .max(
          32,
          t('validation.max', {
            number: 32,
          }),
        )
        .required(t('validation.required'))
        .test('passwords-match', t('validation.match'), function (value) {
          return value === this.parent.password
        }),
    })
    .required()
}
