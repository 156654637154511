import { MeasuresRequest } from '@app/services/cases/types'
import { MeasuresFields } from '../types'

export function measuresCoerceValues(): MeasuresFields {
  return {
    measurement: {
      method: undefined as never,
      files: undefined as never,
    },
    accept: undefined as never,
    published: false,
  }
}

export function mapMeasuresForRequest(
  formValues: MeasuresFields,
): MeasuresRequest {
  return {
    measurementMethod: formValues.measurement?.method as undefined as never,
    published: formValues.published,
  }
}
