import { State } from '@app/core/types'
import { useToast } from '@app/hooks'
import { CaseFile, CaseFileType } from '@app/services/files/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import filesService from '@app/services/files/files.service'
import { groupBy } from '@app/utils/groupby'

type FilesState = State<CaseFile[], 'multimedia'> & {
  error?: Error
}

const initialState: FilesState = {
  loaded: false,
  multimedia: null,
  error: undefined,
}

type UseCaseFilesType = {
  state: FilesState
  loadFiles: (id: string, disableLoadingIndicator: boolean) => void
  filteredImages: {
    [key in CaseFileType]?: CaseFile[]
  }
}

export const useCaseFiles = (caseId: string): UseCaseFilesType => {
  const [state, setState] = useState<FilesState>(initialState)
  const { toastError } = useToast()
  const { t } = useTranslation()

  const loadFiles = useCallback(
    async (id: string, disableLoadingIndicator: boolean = false) => {
      setState(prevState => ({
        ...prevState,
        loaded: disableLoadingIndicator,
      }))

      try {
        const res = await filesService.getCaseFiles(id)
        setState(() => ({
          loaded: true,
          multimedia: res,
          error: undefined,
        }))
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          multimedia: null,
          error: error as Error,
        }))
        toastError(t('errors.load-data'))
      }
    },
    [t, toastError],
  )

  useEffect(() => {
    if (!caseId) {
      return
    }
    loadFiles(caseId)
  }, [loadFiles, caseId])

  const filteredImages = useMemo(() => {
    return groupBy(state.multimedia ?? [], 'identifier')
  }, [state.multimedia])

  return {
    state,
    loadFiles,
    filteredImages,
  }
}
