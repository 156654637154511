export enum PhotoIdentifiers {
  PhotographsFrontal = 'photographs-frontal',
  PhotographsRetractors = 'photographs-retractors',
  PhotographsProfile = 'photographs-profile',
  UpperOcclusal = 'upper-occlusal',
  LowerOcclusal = 'lower-occlusal',
  FrontalOcclusal = 'frontal-occlusal',
  LeftOcclusal = 'left-occlusal',
  RightOcclusal = 'right-occlusal',
  // PhotographsHighlight = 'photographs-highlight',
}

export enum RadioIdentifiers {
  RadioOrth = 'radiography-orthopantomography',
  RadioTel = 'radiography-teleradiography',
  CBCT = 'cbct',
}

export enum STLIdentifiers {
  Upper = 'intraoral-scannings-upper',
  Lower = 'intraoral-scannings-lower',
  Occlusal = 'intraoral-scannings-occlusal',
}

export enum OtherIdentifiers {
  Other = 'other',
}

export enum VariationNames {
  Miniature = 'miniature',
  Thumbnail = 'thumbnail',
}

export type CaseFileType =
  | PhotoIdentifiers
  | RadioIdentifiers
  | STLIdentifiers
  | OtherIdentifiers

export type FileVariant = {
  name: VariationNames
  url: string
}

export type CaseFile = {
  id: number
  identifier: CaseFileType
  legacyKey?: string
  fileName?: string
  url?: string
  fileOriginalName?: string
  variants?: FileVariant[]
  createdAt: string
  updatedAt: string
}

export type CaseFileRequest = {
  identifier: CaseFileType
  originalName: string
  file: File
}
