import { useAppDispatch, useAppSelector } from '@app/store/hooks'
import {
  hideLoader,
  setUserDetail,
  setUserToken,
  showLoader,
} from '@app/store/slices'
import {
  LoginPost,
  RequestRecover,
  RequestCredentials,
} from '@app/services/auth/types'
import authService from '@app/services/auth/auth.service'
import { useToast } from '../toast/useToast'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useAuth = () => {
  const dispatch = useAppDispatch()
  const { token, user } = useAppSelector(state => state.user)

  const { toastError, toastSuccess, toastInfo } = useToast()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const isAuthenticated = () => {
    return token && user
  }

  const login = async (params: LoginPost) => {
    const response = await authService.login(params)

    if (response && response?.data?.user?.roles?.length > 1) {
      window.location.href = import.meta.env.VITE_EMPLOYEE_LOGIN_URL as string

      toastInfo('Redirigiendo al login de empleados...')
      return
    }

    if (response?.data?.token && response?.data?.user) {
      dispatch(setUserToken(response.data.token))
      dispatch(setUserDetail(response.data.user))
    }

    return response
  }

  const logout = async () => {
    dispatch(showLoader())
    try {
      await authService.logout()
      destroySession()
    } catch (e) {
      toastError(t('login.errors.logout'))
    } finally {
      dispatch(hideLoader())
    }
  }

  const checkSession = useCallback(
    async (tokenUrl: string | null) => {
      dispatch(showLoader())
      try {
        const response = await authService.checkSession()
        if (response) {
          dispatch(setUserDetail(response.data))
          tokenUrl && navigate('/dashboard')
        }
      } catch (e) {
        dispatch(setUserToken(null))
        dispatch(setUserDetail(null))
      } finally {
        dispatch(hideLoader())
      }
    },
    [dispatch, navigate],
  )

  const changeTokenByUrl = useCallback(
    async (tokenUrl: string) => {
      try {
        dispatch(showLoader())
        dispatch(setUserToken(tokenUrl))
        dispatch(hideLoader())

        await checkSession(null)

        navigate('/dashboard')
      } catch (e) {
        toastError(t('login.errors.impersonation'))
      }      
    },
    [dispatch, checkSession, navigate, toastError, t],
  )

  const recover = useCallback(
    async (params: RequestRecover) => {
      try {
        await authService.recover(params)
        navigate('/recover-password-email-sent')
        toastSuccess(t('recover-sent.title'))
      } catch (e) {
        toastError(t('recover.errors.recover'))
      }
    },
    [navigate, t, toastError, toastSuccess],
  )

  const recoverByCredentials = useCallback(
    async (credentials: RequestCredentials) => {
      try {
        await authService.recoverByCredentials(credentials)
        navigate('/login')
        toastSuccess(t('profile.success.change-pass'))
      } catch (e) {
        toastError(t('profile.errors.change-pass'))
      }
    },
    [navigate, t, toastError, toastSuccess],
  )

  const destroySession = () => {
    dispatch(setUserToken(null))
    dispatch(setUserDetail(null))
    localStorage.removeItem('language')
  }

  return {
    isAuthenticated,
    login,
    logout,
    checkSession,
    changeTokenByUrl,
    recover,
    recoverByCredentials,
    destroySession,
  }
}
