import { Case, UpdateCaseRequest } from '@app/services/cases/types'
import { CaseStep, CaseEditFormFields } from '../../shared/form-steps/types'
import { Prescription } from '@app/services/prescription/types'
import {
  mapSpecificInfoValuesForRequest,
  specificInfoCoerceValues,
} from '../../shared/form-steps/3-specific-info/utils'
import {
  generalInfoCoerceValues,
  mapGeneralInfoValuesForRequest,
} from '../../shared/form-steps/2-general-info/utils'
import {
  mapMeasuresForRequest,
  measuresCoerceValues,
} from '../../shared/form-steps/6-measures/utils'
import { FormStep } from '@pages/cases/shared/form'
import { CaseFile, CaseFileType } from '@app/services/files/types'
import { groupBy } from '@app/utils/groupby'

export function coerceValues(
  caseData: Case,
  problems: Prescription[] = [],
): CaseEditFormFields {
  const generalInfoCoerce = generalInfoCoerceValues(caseData)
  const specificInfoCoerce = specificInfoCoerceValues(caseData, problems)
  const measuresCoerce = measuresCoerceValues()
  const allFiles = {
    files: groupBy<CaseFile, CaseFileType>(caseData.files, 'identifier'),
  }

  return {
    currentStep: CaseStep.Specific,
    ...generalInfoCoerce,
    ...specificInfoCoerce,
    ...allFiles,
    ...measuresCoerce,
  }
}

export function mapCaseEditFormValuesForRequest(
  formValues: CaseEditFormFields,
  caseId: string,
): UpdateCaseRequest {
  const generalInfoRequest = mapGeneralInfoValuesForRequest(formValues)
  const specificInfoRequest = mapSpecificInfoValuesForRequest(formValues)
  const measuresRequest = mapMeasuresForRequest(formValues)

  return {
    id: caseId,
    ...generalInfoRequest,
    ...specificInfoRequest,
    ...measuresRequest,
  }
}

export function getNextStep(formSteps: FormStep[], currentStep: CaseStep) {
  const currentIndex = formSteps.findIndex(step => step.id === currentStep)

  const prevStep = formSteps[currentIndex + 1]
  const prevStepIndex = formSteps.indexOf(prevStep)

  return formSteps[prevStepIndex].id
}

export function getPrevStep(formSteps: FormStep[], currentStep: CaseStep) {
  const currentIndex = formSteps.findIndex(step => step.id === currentStep)

  const prevStep = formSteps[currentIndex - 1]
  const prevStepIndex = formSteps.indexOf(prevStep)

  return formSteps[prevStepIndex].id
}
