export enum PlanAcceptance {
  Accepted = 'accepted',
  Pending = 'pending',
  Rejected = 'rejected',
  RequestedChanges = 'requestedChanges',
}

export interface TreatmentPlan {
  id: string
  name: string
  caseId: string
  visorUrl: string | null
  createdAt: Date
  acceptance: PlanAcceptance
  permissions: { acceptance: boolean }
  details: {
    diagnosis: string
    upperAligners: number
    lowerAligners: number
    totalAligners: number
    treatmentPlan: string
    recomendations?: string
  }
  pricing: {
    total: number
    discountType: string
    priceWithDiscount: number
  }
  legacyPlan: {
    stripping: Stripping[]
    tads: Tads[]
    attaches: Attaches[]
    movementTable: { upper: string; lower: string }
  }
}

export interface AcceptanceRequest {
  result: PlanAcceptance
  requestedChangesText?: string
  requestedMovements?: null
  treatmentChangeItems?: {
    toothId: number
    description: string
  }[]
  isClientModification?: boolean
}

export type Stripping = {
  title: string
  description: string
  number: number
}

export type Tads = {
  title: string
  description: string
  number: number
}

export type Attaches = {
  title: string
  description: string
  number: number
  upper: number
  lower: number
}
