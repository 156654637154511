import { Card, EmptyList, StateHandler } from '@app/components'
import { useTranslation } from 'react-i18next'
import { useCaseChat } from '@app/hooks/cases'
import { CommentChat } from '@app/services/chat/types'
import clsx from 'clsx'
import { TextMessageBox } from './text-message/TextMessageBox'
import { useCallback, useEffect, useRef } from 'react'
import { BsCheckAll } from 'react-icons/bs'
import { Tooltip } from '@app/components/tooltip/Tooltip'
import { useAppSelector } from '@app/store/hooks'
import { useLocation, Location } from 'react-router-dom'

import './ChatWidget.scss'
import { useDateTime } from '@hooks/dates/useDateTime.ts'

interface ChatWidgetProps {
  caseId: string
}

export const ChatWidget = ({ caseId }: ChatWidgetProps) => {
  const { t } = useTranslation()
  const { user } = useAppSelector(state => state.user)
  const location = useLocation()
  const {
    state: { comments, loaded, error },
    sendMessage,
    changeAsRead,
    lastCommentId,
    hasUnreadComments,
  } = useCaseChat(caseId, user?.id)

  const handleChange = () => {
    if (lastCommentId && hasUnreadComments) {
      changeAsRead(lastCommentId.toString())
    }
  }

  return (
    <div className="ChatWidget">
      <Card>
        <Card.Body>
          <p className="text-gray text-uppercase">{t('cases.chat.title')}</p>
          <StateHandler loaded={loaded} error={error}>
            <ContentComments
              comments={comments!}
              handleAsRead={handleChange}
              location={location}
            />
          </StateHandler>
        </Card.Body>
        <Card.Footer className="ChatWidget-footer">
          <TextMessageBox
            onSendMessage={sendMessage}
            placeholder={t('cases.chat.write')}
          />
        </Card.Footer>
      </Card>
    </div>
  )
}

interface ContentCommentsProps {
  comments: CommentChat[]
  handleAsRead: () => void
  location: Location
}

const ContentComments = ({
  comments,
  handleAsRead,
  location,
}: ContentCommentsProps) => {
  const commentsRef = useRef<HTMLDivElement>(null)
  const lastCommentRef = useRef<HTMLDivElement>(null)

  const handleScroll = useCallback(() => {
    if (commentsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = commentsRef.current

      if (scrollTop + clientHeight >= scrollHeight) {
        handleAsRead()
      }
    }
  }, [handleAsRead])

  const isScrollable = (element: HTMLDivElement) => {
    return element.scrollHeight > element.clientHeight
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.get('tab') === 'assessments') {
      if (lastCommentRef.current) {
        lastCommentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        })
      }
    }
  }, [location, comments.length])

  useEffect(() => {
    if (lastCommentRef.current) {
      lastCommentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }
  }, [comments])

  useEffect(() => {
    const commentsElement = commentsRef.current
    if (!isScrollable(commentsElement!)) {
      handleAsRead()
    }
  }, [handleAsRead, handleScroll])

  useEffect(() => {
    const commentsElement = commentsRef.current
    if (commentsElement) {
      commentsElement.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (commentsElement) {
        commentsElement.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])

  return (
    <div className="ChatWidget-comments" ref={commentsRef}>
      {!comments.length ? (
        <EmptyList className="text-start" title="cases.chat.empty-comments" />
      ) : (
        <>
          {comments.map((comment, i) => (
            <div
              className={clsx('ChatWidget-comment', {
                'is-right': comment.author.isStaff,
              })}
              key={comment.id}
              {...(i === comments.length - 1 ? { ref: lastCommentRef } : {})}
            >
              <Comment comment={comment} />
            </div>
          ))}
        </>
      )}
    </div>
  )
}

interface CommentProps {
  comment: CommentChat
}

const Comment = ({ comment }: CommentProps) => {
  const { t } = useTranslation()
  const { user } = useAppSelector(state => state.user)
  const { formatDate, formatTime } = useDateTime(user?.timezone)
  return (
    <>
      <div className={clsx('ChatWidget-header')}>
        <div>
          <span className="ChatWidget-title">
            {`${comment.author.firstName} ${comment.author.lastName} | ${formatDate(comment.timestamp)} | ${formatTime(comment.timestamp)}`}
          </span>
        </div>

        {comment.isRead ? (
          <span className="ChatWidget-status">
            <Tooltip id={comment.id.toString()} title={t('cases.chat.read')}>
              <BsCheckAll />
            </Tooltip>
          </span>
        ) : (
          <span className="ChatWidget-status not-read"></span>
        )}
      </div>
      <div className="ChatWidget-body">{comment.body}</div>
    </>
  )
}
