import { Card, Detail, EmptyList, StateHandler } from '@app/components'
import { Pagination } from '@app/components/pagination/Pagination'
import { useCaseBilling } from '@app/hooks/cases'
import { useTranslation } from 'react-i18next'
import { AiOutlineDownload } from 'react-icons/ai'
import { downloadDocument } from '@app/utils'
import { useDateTime } from '@hooks/dates/useDateTime.ts'
import { useAppSelector } from '@app/store/hooks.ts'

interface BillingPageProps {
  idCase: string
}

export const CaseDetailBilling = ({ idCase }: BillingPageProps) => {
  return (
    <>
      <Card.Body size={'sm'} className="CaseDetailBilling">
        <Detail.Section>
          <BillingContent idCase={idCase} />
        </Detail.Section>
      </Card.Body>
    </>
  )
}

const BillingContent = ({ idCase }: BillingPageProps) => {
  const { t } = useTranslation()
  const { user } = useAppSelector(state => state.user)
  const { formatDate } = useDateTime(user?.timezone)
  const {
    state: { invoices, loaded, error, lastPage, page, total },
    setPage,
  } = useCaseBilling(idCase)

  return (
    <>
      <StateHandler loaded={loaded} error={error}>
        {!invoices?.length ? (
          <EmptyList title="empty-list.title" />
        ) : (
          <>
            <div className="col-12">
              <div className="row">
                {invoices.map(invoice => (
                  <div className="col-12 col-md-6 mb-3" key={invoice.id}>
                    <Card
                      className="CaseDetailBilling-card"
                      key={`${invoice.id}`}
                    >
                      <Card.Body>
                        <div className="d-flex gap-3">
                          <div>
                            <Card.Title className="mb-0 d-block text-truncate">
                              {invoice.invoiceNumber}
                            </Card.Title>
                            <span>{formatDate(invoice.createdAt)}</span>
                            <p>{`${t('invoices.total', { label: invoice.totals.totalWithTax })}`}</p>
                            <div>
                              <button
                                className="btn btn-secondary"
                                disabled={!invoice.url}
                                type="button"
                                onClick={() =>
                                  downloadDocument(
                                    invoice.url,
                                    invoice.invoiceNumber + '.pdf',
                                  )
                                }
                              >
                                <AiOutlineDownload />
                                {t(`invoices.download`)}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </StateHandler>
      <div className="col-12">
        <Pagination
          loading={!loaded}
          currentPage={page}
          totalPages={lastPage}
          totalElements={total || 0}
          onChange={pageIndex => setPage(pageIndex)}
        />
      </div>
    </>
  )
}
