import { Card, Detail, StateHandler } from '@app/components'
import { useTranslation } from 'react-i18next'
import { useCallback, useState, useEffect } from 'react'
import { ShowImage } from '@app/components/modal/show-image/ShowImage'
import { useCaseFiles } from '@app/hooks/files'
import {
  CaseFile,
  CaseFileType,
  PhotoIdentifiers,
  RadioIdentifiers,
  STLIdentifiers,
} from '@app/services/files/types'
import { CiFileOn, CiSaveDown2 } from 'react-icons/ci'
import { downloadDocument } from '@app/utils'
import { useHandleUpload } from '@app/hooks/files/useHandleUpload'
import { useHandleDelete } from '@app/hooks/files/useHandleDelete'
import { CaseMediaPreview } from '@app/components/media-preview/CaseMediaPreview'
import filesService from '@app/services/files/files.service'
import { groupBy } from '@app/utils/groupby'
import { useForm, FormProvider } from 'react-hook-form'
import './CaseDetailMultimedia.scss'

interface MultimediaPageProps {
  idCase: string
}

export const CaseDetailMultimedia = ({ idCase }: MultimediaPageProps) => {
  const {
    state: { loaded, error },
    filteredImages,
  } = useCaseFiles(idCase)

  const [localFiles, setLocalFiles] = useState<{
    [key in CaseFileType]?: CaseFile[]
  }>({})

  useEffect(() => {
    if (loaded) {
      setLocalFiles(filteredImages)
    }
  }, [loaded, filteredImages])

  return (
    <StateHandler loaded={loaded} error={error}>
      <MultimediaContent
        caseId={idCase}
        files={localFiles}
        onUpload={newFiles => setLocalFiles(newFiles)}
      />
    </StateHandler>
  )
}

interface MultimediaContentProps {
  caseId: string
  files: {
    [key in CaseFileType]?: CaseFile[]
  }
  onUpload: (newFiles: { [key in CaseFileType]?: CaseFile[] }) => void
}

const MultimediaContent = ({
  caseId,
  files,
  onUpload,
}: MultimediaContentProps) => {
  const { t } = useTranslation()
  const [showModalImage, setShowModalImage] = useState(false)
  const [selectedPhoto, setSelectedPhoto] = useState<CaseFile>()
  const methods = useForm()

  const { handleUpload } = useHandleUpload(caseId!)
  const { handleDelete } = useHandleDelete(caseId!)
  const [isUploadingStates, setIsUploadingStates] = useState<{
    [key: string]: boolean
  }>({})

  const filterFileByIdentifier = useCallback(
    (identifier: string) => {
      return files[identifier as CaseFileType] ?? []
    },
    [files],
  )

  const onFileChange = (formName: string) => async (files?: File[]) => {
    const identifier = formName.split('.')[2] as string
    setIsUploadingStates(prev => ({ ...prev, [identifier]: true }))
    await handleUpload(formName, files)
    const updatedFiles = await filesService.getCaseFiles(caseId)
    onUpload(groupBy(updatedFiles, 'identifier'))
    setIsUploadingStates(prev => ({ ...prev, [identifier]: false }))
  }

  const onFileDelete = async (file: CaseFile) => {
    const identifier = file.identifier
    setIsUploadingStates(prev => ({ ...prev, [identifier]: true }))
    await handleDelete([file])
    const updatedFiles = await filesService.getCaseFiles(caseId)
    onUpload(groupBy(updatedFiles, 'identifier'))
    setIsUploadingStates(prev => ({ ...prev, [identifier]: false }))
  }

  const handleShowModal = (file: CaseFile) => {
    setSelectedPhoto(file)
    setShowModalImage(true)
  }

  return (
    <FormProvider {...methods}>
      <form>
        <>
          <Card.Body size={'sm'} className="CaseDetailMultimedia">
            <Detail.Section className="CaseDetailMultimedia-section">
              <>
                <Detail.SubTitle>
                  {t('cases.multimedia.photos')}
                </Detail.SubTitle>
                <div className="CaseDetailMultimedia-grid">
                  {Object.values(PhotoIdentifiers).map(identifier => (
                    <CaseMediaPreview
                      key={identifier}
                      name={`files.photographs.${identifier}.file`}
                      label={t(`cases.multimedia.${identifier}`)}
                      onChange={onFileChange(
                        `files.photographs.${identifier}.file`,
                      )}
                      onImageClick={handleShowModal}
                      identifier={identifier}
                      files={filterFileByIdentifier(identifier)}
                      accept="image/*,capture=camera"
                      handleDelete={onFileDelete}
                      isUploading={isUploadingStates[identifier]}
                    />
                  ))}
                </div>
                <div className="mt-3">
                  <Detail.SubTitle>
                    {t('cases.multimedia.radio')}
                  </Detail.SubTitle>
                  <div className="CaseDetailMultimedia-grid">
                    {Object.values(RadioIdentifiers).map(identifier => (
                      <CaseMediaPreview
                        key={identifier}
                        name={`files.photographs.${identifier}.file`}
                        label={t(`cases.multimedia.${identifier}`)}
                        onChange={onFileChange(
                          `files.photographs.${identifier}.file`,
                        )}
                        onImageClick={handleShowModal}
                        identifier={identifier}
                        files={filterFileByIdentifier(identifier)}
                        accept="image/*,.heic,capture=camera,.dcm,.zip"
                        handleDelete={onFileDelete}
                        isUploading={isUploadingStates[identifier]}
                      />
                    ))}
                  </div>
                </div>
                <div className="mt-3">
                  <Detail.SubTitle>
                    {t('cases.multimedia.files')}
                  </Detail.SubTitle>
                  <div className="CaseDetailMultimedia-grid">
                    {Object.values(STLIdentifiers).map(identifier => (
                      <CaseMediaPreview
                        key={identifier}
                        name={`files.photographs.${identifier}.file`}
                        label={t(`cases.multimedia.${identifier}`)}
                        onChange={onFileChange(
                          `files.photographs.${identifier}.file`,
                        )}
                        onImageClick={handleShowModal}
                        identifier={identifier}
                        files={filterFileByIdentifier(identifier)}
                        accept=".stl,.zip,.dcm"
                        handleDelete={onFileDelete}
                        isUploading={isUploadingStates[identifier]}
                      />
                    ))}
                  </div>
                </div>
                <div className="mt-4">
                  <Detail.SubTitle>
                    {t('cases.multimedia.other')}
                  </Detail.SubTitle>

                  {(files.other ?? []).map((item, index) => (
                    <ItemList
                      item={item}
                      index={index}
                      key={index}
                      title={item.fileName!}
                    />
                  ))}
                </div>
              </>
            </Detail.Section>
          </Card.Body>
          {showModalImage && (
            <ShowImage
              setShowModal={setShowModalImage}
              photo={selectedPhoto!}
            />
          )}
        </>
      </form>
    </FormProvider>
  )
}

interface ItemListProps {
  item: CaseFile
  index: number
  title: string
}

const ItemList = ({ item, index, title }: ItemListProps) => {
  return (
    <div
      className="CaseDetailMultimedia-list"
      key={`${item.identifier}-${index}`}
    >
      <div>
        <CiFileOn />
        <span>{title}</span>
      </div>
      <button
        className="btn btn-secondary btn-sm"
        onClick={() => downloadDocument(item.url, item.fileOriginalName)}
        disabled={!item.url}
      >
        <CiSaveDown2 />
      </button>
    </div>
  )
}
