import { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { CiCircleCheck } from 'react-icons/ci'
import {
  PlanAcceptance,
  TreatmentPlan,
} from '@app/services/treatment-plans/types'
import { EmptyList } from '@app/components'

import './TreatmentHistory.scss'
import { useDateTime } from '@hooks/dates/useDateTime.ts'
import { useAppSelector } from '@app/store/hooks.ts'

type TreatmentHistoryProps = {
  treatmentPlans: TreatmentPlan[]
  selectedPlan?: TreatmentPlan
  setSelectedPlan: Dispatch<SetStateAction<TreatmentPlan | undefined>>
}
export const TreatmentHistory: FC<TreatmentHistoryProps> = ({
  treatmentPlans,
  selectedPlan,
  setSelectedPlan,
}) => {
  const { t } = useTranslation()
  const { user } = useAppSelector(state => state.user)
  const { formatDate } = useDateTime(user?.timezone)

  const isSelectedPlan = useCallback(
    (planId: string) => selectedPlan?.id === planId,
    [selectedPlan?.id],
  )

  return (
    <>
      <p className="text-gray text-uppercase">{t('cases.treatment-history')}</p>
      <div className="list-group list-group-flush">
        {!treatmentPlans?.length ? (
          <EmptyList className="text-start" title="empty-list.title" />
        ) : (
          <>
            {treatmentPlans?.map(plan => (
              <button
                key={plan.id}
                type="button"
                className={clsx(
                  'list-group-item list-group-item-action TreatmentHistory-listItem',
                  {
                    'active fw-semibold': isSelectedPlan(plan.id),
                  },
                )}
                aria-current={isSelectedPlan(plan.id)}
                onClick={() => setSelectedPlan(plan)}
              >
                {plan.name}

                {plan.acceptance === PlanAcceptance.Accepted && (
                  <span className="ms-auto fs-5 text-success d-flex">
                    <CiCircleCheck />
                  </span>
                )}

                <small className="text-gray fw-normal">
                  {formatDate(plan.createdAt)}
                </small>
              </button>
            ))}
          </>
        )}
      </div>
    </>
  )
}
