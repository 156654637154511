import { object, string, mixed, ObjectSchema } from 'yup'
import { TFunction } from 'i18next'
import { RefinementFormFields } from './types'

export function refinementFormValidationSchema(
  t: TFunction,
): ObjectSchema<RefinementFormFields> {
  return object()
    .shape({
      reason: string()
        .max(
          30,
          t('validation.max', {
            number: 30,
          }),
        )
        .required(t('validation.required')),
      details: string()
        .min(
          4,
          t('validation.min', {
            number: 4,
          }),
        )
        .required(t('validation.required')),
      files: mixed<File[]>().nullable().notRequired(),
    })
    .required()
}
