import { CaseFileRequest, CaseFileType } from '@app/services/files/types'
import { useCallback } from 'react'
import { useCaseFileDetail } from './useCaseFileDetail'

export const useHandleUpload = (caseId: string) => {
  const { uploadFile, deleteFile } = useCaseFileDetail(caseId)

  const handleUpload = useCallback(
    async (formName: string, files?: File[]) => {
      const fileId = formName.split('.')[2] as CaseFileType

      if (files && files.length > 0) {
        for (const file of files) {
          const request: CaseFileRequest = {
            identifier: fileId,
            originalName: file.name,
            file,
          }

          await uploadFile(request)
        }
      } else {
        await deleteFile(fileId)
      }
    },
    [uploadFile, deleteFile],
  )

  return { handleUpload }
}
