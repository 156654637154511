import clsx from 'clsx'
import { FC, useCallback, useMemo, useEffect, useState } from 'react'
import Image from 'react-bootstrap/Image'
import { useTranslation } from 'react-i18next'
import { CiImageOn } from 'react-icons/ci'
import { MediaMenuActions } from './media-menu-actions/MediaMenuActions'
import { getDefaultImage as getFileIcon } from '@app/utils/images/getDefaultImage'
import { Loading } from '@app/components'
import { CaseFile } from '@app/services/files/types'
import { MediaMenuAdditionalFiles } from '@components/media-preview/media-menu-additional-files/MediaMenuAdditionalFiles'
import {
  getFileExtension,
  isPreviewableImage,
} from '@app/utils/images/fileUtils'
import { useToast } from '@app/hooks'

import './MediaPreview.scss'

interface MediaPreviewProps {
  id: string
  identifier?: string
  handleClickImage?: (id: string) => void
  handleClear?: () => Promise<void>
  handleUpload?: () => void
  handleDelete?: (file: CaseFile) => Promise<void>
  title?: string
  imagePreview?: string
  files?: CaseFile[]
  isUploading?: boolean
}

export const MediaPreview: FC<MediaPreviewProps> = ({
  id,
  identifier,
  title,
  handleClear,
  handleUpload,
  handleDelete,
  handleClickImage,
  imagePreview,
  files,
  isUploading,
}) => {
  const { t } = useTranslation()
  const { toastInfo } = useToast()
  const [isToastVisible, setIsToastVisible] = useState(false)

  const label = useMemo(() => title ?? t('common.no-file'), [title, t])

  const lastUploadedFile = useMemo(() => files?.[files.length - 1], [files])
  const thumbnailVariant = useMemo(
    () =>
      lastUploadedFile?.variants?.find(variant => variant.name === 'thumbnail'),
    [lastUploadedFile?.variants],
  )
  const url = useMemo(
    () => imagePreview || thumbnailVariant?.url || lastUploadedFile?.url,
    [imagePreview, thumbnailVariant?.url, lastUploadedFile?.url],
  )

  const extension = useMemo(
    () => getFileExtension(lastUploadedFile?.fileName),
    [lastUploadedFile?.fileName],
  )

  const iconToUse = useMemo(() => {
    let icon = getFileIcon(identifier)
    if (lastUploadedFile) {
      if (extension) {
        if (extension === 'stl') {
          icon = getFileIcon('stl')
        } else if (extension === 'heic') {
          icon = getFileIcon('heic')
        } else if (!isPreviewableImage(extension)) {
          icon = getFileIcon('stl')
        }
      }
    }
    return icon
  }, [identifier, lastUploadedFile, extension])

  const shouldPreviewImage = useCallback((): boolean => {
    if (!url) {
      return false
    }

    const extension = getFileExtension(url)

    return url.startsWith('data:image') || isPreviewableImage(extension)
  }, [url])

  const handleEmptyImageClick = useCallback(() => {
    if (handleUpload) {
      handleUpload()
    }
  }, [handleUpload])

  const handleDeleteFile = useCallback(
    async (file: CaseFile) => {
      if (handleDelete) {
        await handleDelete(file)
      }
    },
    [handleDelete],
  )

  useEffect(() => {
    if (isUploading && identifier === 'cbct' && !isToastVisible) {
      toastInfo(t('files.info.upload'))
      setIsToastVisible(true)
    }
  }, [isUploading, identifier, isToastVisible, toastInfo, t])

  useEffect(() => {
    if (!isUploading) {
      setIsToastVisible(false)
    }
  }, [isUploading])

  const renderImage = useCallback(() => {
    if (shouldPreviewImage()) {
      return (
        <Image
          src={url}
          alt={label}
          rounded
          className={clsx('MediaPreview-image', { 'no-photo': !url })}
          onClick={
            url && handleClickImage ? () => handleClickImage(id) : undefined
          }
        />
      )
    }

    return (
      <div className="MediaPreview-image-withoutUpload">
        {iconToUse ? (
          <Image src={iconToUse} alt={label} className="icon-md" />
        ) : (
          <CiImageOn className="icon-md" />
        )}
      </div>
    )
  }, [shouldPreviewImage, url, handleClickImage, id, iconToUse, label])

  const renderEmptyImage = useCallback(
    () => (
      <div className="MediaPreview-image" onClick={handleEmptyImageClick}>
        {iconToUse ? (
          <Image src={iconToUse} alt={label} className="icon-md" />
        ) : (
          <CiImageOn className="icon-md" />
        )}
      </div>
    ),
    [handleEmptyImageClick, iconToUse, label],
  )

  const imageContent = useMemo(
    () => (url ? renderImage() : renderEmptyImage()),
    [url, renderImage, renderEmptyImage],
  )

  return (
    <div className="MediaPreview">
      <div className="MediaPreview-loading-container">
        {isUploading && <Loading />}
        {imageContent}
      </div>
      <div className="MediaPreview-menu">
        <p className="text-truncate">{label}</p>
        <MediaMenuAdditionalFiles
          id={id}
          files={files ?? []}
          handleDelete={handleDeleteFile}
        />
        <MediaMenuActions
          id={id}
          url={url}
          handleUpload={handleUpload}
          handleClear={handleClear}
        />
      </div>
    </div>
  )
}
