import React, { forwardRef } from 'react'
import { clsx } from 'clsx'
import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  useFormContext,
} from 'react-hook-form'
import {
  FormControlWrapper,
  FormControlWrapperProps,
} from '../form-control-wrapper/FormControlWrapper'

export interface RadioOption {
  value: string | boolean
  label: string
}

type RadioControlProps<T extends FieldValues> = {
  disabled?: boolean
  readonly?: boolean
  className?: string
  options: RadioOption[]
  direction?: 'col' | 'row'
  onChange?: (v: string | boolean) => void
  inputRef?: React.Ref<HTMLInputElement>
  defaultValue?: string | boolean
} & FormControlWrapperProps<T>

export const RadioControl = forwardRef(function <T extends FieldValues>(
  {
    warning,
    name,
    required,
    id,
    label,
    disabled,
    readonly,
    options,
    className,
    hiddenLabel,
    direction,
    onChange,
    inputRef,
    defaultValue,
  }: RadioControlProps<T>,
  ref: React.Ref<HTMLDivElement>,
): JSX.Element {
  const methods = useFormContext<T>()

  const { control } = methods

  return (
    <div className={clsx('RadioControl', className)} ref={ref}>
      <div className="form-label-wrapper">
        <label
          className={clsx('form-label', {
            required,
            'has-warning': warning,
            'd-none': hiddenLabel,
          })}
          htmlFor={id}
        >
          {label}
        </label>
      </div>

      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue as PathValue<T, Path<T>>}
        render={({ field, fieldState }) => (
          <FormControlWrapper
            error={fieldState.error}
            warning={warning}
            name={field.name}
            className={clsx(`display-${direction}`)}
          >
            {options.map(({ value, label }) => {
              const prefixedId = id ?? `radio-${name}-${value}`

              return (
                <div className="form-check" key={value.toString()}>
                  <input
                    key={value.toString()}
                    className={clsx('form-check-input', {
                      'is-invalid': fieldState.error,
                      'has-warning': warning,
                    })}
                    type={'radio'}
                    id={prefixedId}
                    disabled={disabled}
                    value={value.toString()}
                    name={field.name}
                    readOnly={readonly}
                    required={required}
                    checked={field.value === value}
                    onChange={() => {
                      field.onChange(value)
                      if (onChange) onChange(value)
                    }}
                    onBlur={field.onBlur}
                    ref={e => {
                      field.ref(e)
                      if (inputRef) {
                        if (typeof inputRef === 'function') {
                          inputRef(e)
                        } else if (inputRef.current !== undefined) {
                          ;(
                            inputRef as React.MutableRefObject<HTMLInputElement | null>
                          ).current = e
                        }
                      }
                    }}
                  />

                  <label
                    className={clsx('form-check-label', {
                      required,
                      'has-warning': warning,
                    })}
                    htmlFor={prefixedId}
                  >
                    {label}
                  </label>
                </div>
              )
            })}
          </FormControlWrapper>
        )}
      />
    </div>
  )
})
